import {VUE_APP_CHANNEL_NAME} from '@/const';
export const CHANNEL_KEY_API = VUE_APP_CHANNEL_NAME + '.json';
export const API_KEY_API = '/get_api_key';
export const PROFILE_API = '/profile';
export const USER_ACCESS_API = '/user/access';
export const LOGIN_API = 'auth/login';
export const LOGOUT_API = 'auth/logout';
export const REGISTER_API = 'auth/register';
export const EMAIL_VERIFY_API = 'auth/email-verify';
export const PASSWORD_RESET_REQUEST_API = 'auth/password-reset-request';
export const PASSWORD_RESET_API = 'auth/password-reset';
export const ADD_SESSION_API= 'auth/add_session';
export const SETTINGS_API = '/settings';
export const MENU_API = '/menu';
export const SLIDER_API = '/slides/web';
export const PAGES_API = '/pages';
export const COLOR_API = '/color-settings/web';
export const CLIP_API = '/clip';
export const CLIPS_FOR_EVENT_API = '/clips/event';
export const SHARED_CLIPS_API = '/shared-clip';
export const DOWNLOAD_CLIP_API = '/clip/download';
export const USER_CLIPS_API = `/clips/user`;
export const EVENTS_WITH_CLIP_API = '/events/with_clip';
export const CUSTOM_FORM_API = '/custom-forms';
export const EVENTS_API = '/events';
export const DOWNLOAD_EVENT_API = 'download-event';
export const EVENT_CATEGORIES_API = '/events-categories';
export const HOMEPAGE_EVENTS_API = '/events-for-homepage';
export const FAQ_API = '/faq';
export const FAVORITES_API = '/favorites';
export const FAVORITE_API = '/favorite';
export const FAVORITE_EVENTS_API = '/events/favorite';
export const GEO_API = '/geo';
export const PARTICIPANTS_API = '/participants';
export const PARTICIPANT_GROUP_API = '/participant-groups';
export const PARTICIPANT_GROUP_PROPERTIES_API = '/participant-group-properties';
export const STRIPE_BUY_API = 'stripe/buy';
export const PACKAGES_API = 'packages';
export const SUBSCRIPTIONS_API = 'subscriptions';
export const ORDERS_API = 'orders';
export const APPLY_COUPON_API = 'apply_coupon';
export const STRIPE_UNSUBSCRIBE_API = 'stripe/unsubscribe';
export const STRIPE_PORTAL_API = '/stripe/customer-portal';
export const STRIPE_CARDS_API = 'stripe/list-cards';
export const VOD_ITEMS_API = 'vod-media-items';
export const VOD_CATEGORIES_API = 'vod-media-categories';
export const VOD_SEARCH_API = 'vod-media/search';
export const SOCIAL_AUTH_API = 'auth/social';


export const NO_API_KEY_URLS = [CHANNEL_KEY_API, API_KEY_API];
